import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import GalleryPage from './pages/gallery/GalleryPage';
import AboutPage from './pages/about/AboutPage';
import Header from './components/header/Header';

function App() {
  return (
    <BrowserRouter>
      <Header/>
      <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/gallery" element={<GalleryPage/>} />
          <Route path="/about" element={<AboutPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
