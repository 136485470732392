import React, { ReactElement, useEffect, useState } from "react";
import './SlideIn.css';
import Slide from "@mui/material/Slide";
import { useInView } from 'react-intersection-observer';


type Props = {
    canAppear: boolean;
    children: ReactElement[];
}

const SlideIn = ({
    canAppear,
    children,
}: Props) => {
    const [slideIn, setSlideIn] = useState(false);

    // Using 'react-intersection-observer' to detect when the image is in view
    const { ref, inView } = useInView({
      triggerOnce: true, // Animation triggers only once
      threshold: 0.2,    // Trigger when 10% of the image is visible
    });

    useEffect(() => {
        if (inView) {
            setTimeout(() => setSlideIn(true), 200);
        }
    }, [inView]);

    return (
        <div ref={ref}>
            <Slide direction="up" in={canAppear && slideIn} timeout={700}>
                <div className='slide-in'>
                    {children}
                </div>
            </Slide>
        </div>
    )
}

export default SlideIn;
