import React from 'react';
import './HomePage.css';
import { Link } from 'react-router-dom';

const HomePage = () => {
    const isMobile = window.innerWidth <= 768;

    if (isMobile) return (
        <div className='home-divide-mobile'>
            <Link to='/gallery' className='link-box-mobile'>
                <div className='image-link il-left'></div>
                <h1 className='midtext handwritten'>gallery</h1>
            </Link>
            <Link to='/about' className='link-box-mobile'>
                <div className='image-link il-right'></div>
                <h1 className='midtext handwritten'>about</h1>
            </Link>
        </div>
    )


    return (
        <div className='home-divide'>
            <Link to='/gallery' className='link-box'>
                <div className='image-link il-left'></div>
                <h1 className='midtext handwritten'>gallery</h1>
            </Link>
            <Link to='/about' className='link-box'>
                <div className='image-link il-right'></div>
                <h1 className='midtext handwritten'>about</h1>
            </Link>
        </div>
    )
}

export default HomePage;
