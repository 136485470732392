import React, { useState, useEffect } from 'react';
import styles from './GalleryPage.module.css';
import SlideIn from '../../components/slidein/SlideIn';

import WalkWalk from '../../assets/gallery/walkwalk.mp4';
import BackBack from '../../assets/gallery/backback.png';
import SMD from '../../assets/gallery/smd.png';
import Pump from '../../assets/gallery/pump.png';
import Beat from '../../assets/gallery/beat.png';
import Barbie from '../../assets/gallery/barbie.png';
import Tube from '../../assets/gallery/tube.png';
import Pillow from '../../assets/gallery/pillow.png';


const sampleTopText = "welcome to my arttttt :P";



const fetchTopText = () => {
    return sampleTopText;
}

const GalleryPage = () => {
    const [totalTopText, setTotalTopText] = useState('');
    const [topText, setTopText] = useState('');

    const isMobile = window.innerWidth <= 768;

    // Fetch parameters
    useEffect(() => {
        setTotalTopText(fetchTopText());
    }, [])

    // Animate typing
    useEffect(() => {
        if (topText.length < totalTopText.length) {
            setTimeout(() => {
                setTopText(topText + totalTopText[topText.length]);
            }, 75)
        }
    }, [totalTopText, topText])


    const canAppear: boolean = topText.length === totalTopText.length; 


    const leftCol = [
        <SlideIn canAppear={canAppear} >
            <img src={BackBack} alt='' style={{maxWidth: 'min(27rem, 80vw)', height: 'auto'}}/>
            <h4 className={styles['work-title']}> Untitled, 2024</h4>
        </SlideIn>,
        <SlideIn canAppear={canAppear} >
            <img src={Pump} alt='' style={{maxWidth: 'min(27rem, 80vw)', height: 'auto'}}/>
            <h4 className={styles['work-title']}> {"Pump Me Up! <3"}, 2024</h4>
        </SlideIn>,
        <SlideIn canAppear={canAppear} >
            <img src={Pillow} alt='' style={{maxWidth: 'min(27rem, 80vw)', height: 'auto'}}/>
            <h4 className={styles['work-title']}> Identity comfort, 2024</h4>
        </SlideIn>,
        <SlideIn canAppear={canAppear} >
            <img src={Tube} alt='' style={{maxWidth: 'min(27rem, 80vw)', height: 'auto'}}/>
            <h4 className={styles['work-title']}> 0---0, 2024</h4>
        </SlideIn>,
        
    ]
    const rightCol = [
        <SlideIn canAppear={canAppear} >
            <img src={SMD} alt='' style={{maxWidth: 'min(27rem, 80vw)', height: 'auto'}}/>
            <h4 className={styles['work-title']}> Suck My D*ck, 2023</h4>
        </SlideIn>,
        <SlideIn canAppear={canAppear} >
            <img src={Beat} alt='' style={{maxWidth: 'min(27rem, 80vw)', height: 'auto'}}/>
            <h4 className={styles['work-title']}> Beat, 2022</h4>
        </SlideIn>,
        <SlideIn canAppear={canAppear} >
            <img src={Barbie} alt='' style={{maxWidth: 'min(27rem, 80vw)', height: 'auto'}}/>
            <h4 className={styles['work-title']}> Barbie, 2024</h4>
        </SlideIn>,
        <SlideIn canAppear={canAppear} >
            <video controls src={WalkWalk} style={{maxWidth: 'min(27rem, 80vw)', height: 'auto'}}/>
            <h4 className={styles['work-title']}> WALKING THE WALK, 2024</h4>
        </SlideIn>
    ]

    if (isMobile) {
        return (
            <div className={styles['gallery-mobile']}>
                <p className={`${styles['top-text']} handwritten`}>{topText}</p>
                {leftCol}
                {rightCol}
            </div>
        )
    }

    return (
        <div className={styles['gallery']}>
            <div className={styles['column-left']}>
                <p className={`${styles['top-text']} handwritten`}>{topText}</p>
                {leftCol}
            </div>
            <div className={styles['column-right']}>   
                {rightCol}
            </div>
        </div>
    )
}

export default GalleryPage;
