import React from "react";
import bar from '../../assets/bar.png';
import peauLogo from '../../assets/peau-logo.png';
import styles from './Header.module.css';
import { NavLink } from "react-router-dom";
import { ReactComponent as Insta } from '../../assets/insta.svg';

const Header = () => {
    return (
        <header>
            <div className={styles['container']}>
                <img src={peauLogo} className={styles['logo']} alt=""/>
                <div className={styles['left-content']}>
                    <div className={`${styles['links']} handwritten`}>
                        <NavLink to='/'> home </NavLink>
                        <NavLink to='/gallery'> gallery </NavLink>
                        <NavLink to='/about'> about </NavLink>
                    </div>
                    <a href="https://www.instagram.com/peeaau/" 
                        rel="noreferrer"
                        style={{marginTop: 'auto'}}
                        target="_blank" > 
                        <Insta className={styles['insta']} />
                    </a>
                </div>
            </div>
            <img src={bar} className={styles['bar']} alt=""/>
        </header>
    )
}

export default Header;
