import React from 'react';
import ArtBoard from '../../assets/home/board.png';
import './AboutPage.css';

const AboutPage = () => {
    const isMobile = window.innerWidth <= 768;

    if (isMobile) return (
        <div className='about-page'>
            <h1>About Me</h1>
            <img src={ArtBoard} alt='Artist Board' className='art-board-mobile' />
            <h4>Artist Board</h4>
            <p className='blurb-mobile'>
                As a Canadian artist, I delve into the intricate tapestry of human existence through my works. My art serves as a mirror reflecting the struggles within myself and my humanity. These struggles encompass mental health, sexuality, and gender, which have profoundly influenced my creative journey. I seek refuge within my pieces, using them as a canvas to express the potential unification of people within our individualistic existences and to highlight our shared experiences.
            </p>
        </div>
    );


    return (
        <div className='about-page'>
            <h1>About Me</h1>
            <img src={ArtBoard} alt='Artist Board' className='art-board' />
            <h4>Artist Board</h4>
            <p className='blurb'>
                As a Canadian artist, I delve into the intricate tapestry of human existence through my works. My art serves as a mirror reflecting the struggles within myself and my humanity. These struggles encompass mental health, sexuality, and gender, which have profoundly influenced my creative journey. I seek refuge within my pieces, using them as a canvas to express the potential unification of people within our individualistic existences and to highlight our shared experiences.
            </p>
        </div>
    );
}

export default AboutPage;
